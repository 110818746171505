import React from "react";
import {
  SEO,
  PageLayout,
  Image,
  Content,
  OutboundLink,
  FadeReveal,
  multipleComponentIterator,
  FullImageCard,
  InternalLink,
  FishermanIcon,
  MarkdownContent,
  MultipleComponentIterator,
  SlideReveal,
  BackgroundImageTeamMember,
  BasicCarousel,
  SocialMediaV2,
  ReviewV2,
} from "@bluefin/components";
import { Grid, Header, Button, p, Card } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
  withNamedArgs,
  getBusinessFiles,
} from "../utils/utils";
import {
  createServiceCategorySlug,
  createTeamMemberSlug,
} from "@bluefin/gatsby-plugin-fisherman-page-creator";

export default class IndexPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessMenuCategory,
      allFishermanBusinessFile,
      allFishermanBusinessTeam,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout hero={false} subfooter={false} className={"home-page"}>
          <Image
            src={getComponentFiles({
              components: fishermanBusinessWebsitePage.components,
              componentName: "HeroElement",
              numToSelect: 1,
            })}
            className={"image hero image-overlay"}
            background={true}
          >
            <Grid
              className={"home-section-container"}
              stackable={true}
              textAlign={"center"}
            >
              <Grid.Column width={16}>
                <Header
                  as={"h1"}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "home_section_header",
                    defaultValue: "Home",
                  })}
                />
                <Content textAlign={"center"}>
                  <Content.Markup
                    width={8}
                    isMarkdown={true}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentId: "home_section_description",
                    })}
                    extra={
                      <div className={"ctas-container"}>
                        <Button
                          primary={true}
                          as={OutboundLink}
                          to={"javascript:blvd.openBookingWidget();"}
                          newTab={false}
                          event={{
                            category: "Booking",
                            action: "Schedule Appointment Intent",
                          }}
                        >
                          Book Now
                        </Button>
                      </div>
                    }
                  />
                </Content>
              </Grid.Column>
            </Grid>
          </Image>
          <div className={"services-section"}>
            <Card.Group className={"services"} itemsPerRow={3} stackable={true}>
              <FadeReveal
                cascade={true}
                cascadeFactor={0.4}
                triggerOnce={true}
                className={"ui card"}
                disableAnimationOnMobile={true}
                noContainer={true}
                children={multipleComponentIterator({
                  iterator: allFishermanBusinessMenuCategory.nodes,
                  limit: 3,
                  components: [
                    {
                      component: (
                        <FullImageCard
                          link={true}
                          as={InternalLink}
                          event={{
                            category: "Services",
                            action: "View Category Details",
                          }}
                        />
                      ),
                      propMap: {
                        key: "_id",
                        image: withNamedArgs({
                          func: getBusinessFiles,
                          args: {
                            businessFiles: allFishermanBusinessFile.nodes,
                            numToSelect: 1,
                          },
                        }),
                        "event.label": "name",
                        to: createServiceCategorySlug,
                      },
                      children: [
                        {
                          component: <Card.Header />,
                          propMap: { content: "name", key: "name" },
                        },
                        {
                          component: <FishermanIcon iconName={"arrow-right"} />,
                        },
                      ],
                    },
                  ],
                })}
              />
            </Card.Group>
            <Button
              primary={true}
              to={"/services/"}
              event={{ category: "Services", action: "View All Services" }}
              as={InternalLink}
            >
              See All Services
            </Button>
          </div>
          <div className={"about-section"}>
            <Grid stackable={true} doubling={true} columns={2}>
              <Grid.Column width={8} className={"markdown-column"}>
                <FadeReveal duration={1250} triggerOnce={true}>
                  <MarkdownContent
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentId: "about_section_description",
                    })}
                  />
                </FadeReveal>
              </Grid.Column>
              <Grid.Column width={8} className={"image-column"}>
                <FadeReveal duration={1250} triggerOnce={true} delay={450}>
                  <Image
                    className={"image"}
                    src={getComponentFiles({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "Content.Image",
                      componentIdentifier: "about_section_image",
                      numToSelect: 1,
                    })}
                  />
                </FadeReveal>
              </Grid.Column>
            </Grid>
          </div>
          <div className={"stylists-section"}>
            <Header
              as={"h2"}
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "staff_section_header",
                defaultValue: "OUR TOP STYLISTS",
              })}
            />
            <MarkdownContent
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "staff_section_description",
              })}
            />
            <Card.Group className={"featured-stylists"} itemsPerRow={3}>
              <MultipleComponentIterator
                iterator={allFishermanBusinessTeam.nodes}
                limit={3}
                components={[
                  {
                    component: (
                      <SlideReveal
                        className={"ui card"}
                        fade={true}
                        direction={"up"}
                        triggerOnce={true}
                      />
                    ),
                    propMap: { key: "_id" },
                    children: [
                      {
                        component: (
                          <BackgroundImageTeamMember
                            useModal={false}
                            as={InternalLink}
                          />
                        ),
                        propMap: {
                          "teamMember.name": "name",
                          "teamMember.role": "role",
                          "teamMember.photo": "gatsbyImage",
                          "teamMember.description": "description",
                          "teamMember.email": "email",
                          "teamMember.phone": "phone",
                          "teamMember.locations": "locations",
                          "teamMember.hours": "hours",
                          to: createTeamMemberSlug,
                        },
                      },
                    ],
                  },
                ]}
              />
            </Card.Group>
            <Button
              to={"/team/"}
              primary={true}
              event={{ category: "Team", action: "View All Team Members" }}
              as={InternalLink}
            >
              See All Team Members
            </Button>
          </div>
          <div className={"product-section"}>
            <Grid
              stackable={true}
              doubling={true}
              columns={2}
              reversed={"mobile"}
            >
              <Grid.Column width={8} className={"image-column"}>
                <div className={"image-container"}>
                  <BasicCarousel
                    className={""}
                    slide={true}
                    size={1}
                    displayIndicators={false}
                    displayPlayPauseButton={false}
                    autoScroll={false}
                    animation={{
                      name: "blur",
                      duration: 200,
                      transitionProps: {
                        animating: { blur: "2px", opacity: 0.99 },
                        preAnimating: { blur: "2px", opacity: 0.99 },
                      },
                    }}
                    mobile={{ size: 1 }}
                    items={[
                      <div>
                        <div className={"image-overlay"} />
                        <Image
                          src={
                            " https://fisherman.gumlet.io/public/cd8ecfb0-43a0-44bf-9af3-9bc6e8c6f797/1.jpg"
                          }
                          alt={""}
                        />
                        <div className={"image-content"}>
                          <div>
                            <Header
                              as={"h3"}
                              content={"Helps Blondes Stay Vibrant"}
                            />
                          </div>
                          <div>
                            <p>L'Oréal Professionnel</p>
                            <Header as={"h4"} content={"Blondifier Shampoo"} />
                            <Button
                              primary={true}
                              to={
                                "https://shop.saloninteractive.com/store/SalonKathleenEnumclaw/product/62912/66421"
                              }
                              as={OutboundLink}
                              content={"Shop Now"}
                            />
                          </div>
                        </div>
                      </div>,
                      <div>
                        <div className={"image-overlay"} />
                        <Image
                          src={
                            "https://fisherman.gumlet.io/public/cd8ecfb0-43a0-44bf-9af3-9bc6e8c6f797/2.jpg"
                          }
                          alt={""}
                        />
                        <div className={"image-content"}>
                          <div>
                            <Header
                              as={"h3"}
                              content={"Intensive Hair Repair"}
                            />
                          </div>
                          <div>
                            <p>L'Oréal Professionnel</p>
                            <Header
                              as={"h4"}
                              content={
                                "Absolut Repair 10-in-1 Multi-Benefit Oil"
                              }
                            />
                            <Button
                              primary={true}
                              to={
                                "https://shop.saloninteractive.com/store/SalonKathleenEnumclaw/product/62887/66396"
                              }
                              as={OutboundLink}
                              content={"Shop Now"}
                            />
                          </div>
                        </div>
                      </div>,
                      <div>
                        <div className={"image-overlay"} />
                        <Image
                          src={
                            "https://fisherman.gumlet.io/public/cd8ecfb0-43a0-44bf-9af3-9bc6e8c6f797/3.jpg"
                          }
                          alt={""}
                        />
                        <div className={"image-content"}>
                          <div>
                            <Header
                              as={"h3"}
                              content={"Keep Your Hair Fresh"}
                            />
                          </div>
                          <div>
                            <p>L'Oréal Professionnel</p>
                            <Header
                              as={"h4"}
                              content={"Morning After Dust Dry Shampoo"}
                            />
                            <Button
                              primary={true}
                              to={
                                "https://shop.saloninteractive.com/store/SalonKathleenEnumclaw/product/53044/56503"
                              }
                              as={OutboundLink}
                              content={"Shop Now"}
                            />
                          </div>
                        </div>
                      </div>,
                    ]}
                  />
                </div>
              </Grid.Column>
              <Grid.Column width={8} className={"product-details-column"}>
                <FadeReveal duration={1250} triggerOnce={true}>
                  <Header as={"h2"} content={"Our Favorite Products"} />
                  <p>
                    Looking for salon results every day? Support our small
                    business by buying recommended products directly from us
                    online. We only include brands and products our stylists
                    would use on our own hair. Ask your stylist for customized
                    recommendations at your next appointment!
                  </p>
                  <Button
                    primary={true}
                    to={
                      "https://shop.saloninteractive.com/store/SalonKathleenEnumclaw"
                    }
                    as={OutboundLink}
                    content={"Browse All Products"}
                  />
                </FadeReveal>
              </Grid.Column>
            </Grid>
          </div>
          <div className={"image-gallery-section"}>
            <Header
              as={"h2"}
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "image_section_header",
                defaultValue: "WE'RE SOCIAL",
              })}
            />
            <MarkdownContent
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "image_section_description",
              })}
            />
            <SocialMediaV2>
              <MultipleComponentIterator
                iterator={fishermanBusiness.socialMedia}
                components={[
                  {
                    component: <SocialMediaV2.Platform />,
                    propMap: { key: "_id", url: "link", platform: "type" },
                    children: [
                      {
                        component: <SocialMediaV2.Icon />,
                        propMap: { icon: "type" },
                      },
                      {
                        component: <SocialMediaV2.Handle />,
                        propMap: { link: "link" },
                      },
                    ],
                  },
                ]}
              />
            </SocialMediaV2>
            <div
              className={"elfsight-app-983aa3f8-c059-493b-bf33-f6bcddfd6296"}
              data-elfsight-app-lazy={true}
            />
          </div>
          <div className={"reviews-section"}>
            <BasicCarousel
              size={3}
              displayIndicators={false}
              stepSize={1}
              autoScroll={false}
              displayPlayPauseButton={false}
              animation={{ name: "blur", duration: 160 }}
              items={multipleComponentIterator({
                iterator: fishermanBusiness.reviews,
                components: [
                  {
                    component: <ReviewV2 />,
                    propMap: { key: "_id" },
                    children: [
                      { component: <FishermanIcon iconName={"quote-left"} /> },
                      {
                        component: <ReviewV2.Text charLimit={360} />,
                        propMap: { text: "text" },
                      },
                      {
                        component: <ReviewV2.Line />,
                        children: [
                          {
                            component: <ReviewV2.Rating />,
                            propMap: { rating: "rating" },
                          },
                          {
                            component: <ReviewV2.RatingLabel />,
                            propMap: { rating: "rating" },
                          },
                        ],
                      },
                      {
                        component: <ReviewV2.Line />,
                        children: [
                          {
                            component: <ReviewV2.Author />,
                            propMap: { author: "author" },
                          },
                          {
                            component: <ReviewV2.Source />,
                            propMap: { source: "source", link: "link" },
                          },
                        ],
                      },
                    ],
                  },
                ],
              })}
            />
            <div className={"submit-review-button-container"} />
          </div>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      socialMedia {
        _id
        link
        type
      }
      reviews {
        _id
        author
        text
        source
        rating
        link
      }
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          content
        }
      }
    }
    allFishermanBusinessMenuCategory(
      sort: { fields: [isFeatured, order], order: [DESC, ASC] }
    ) {
      nodes {
        description
        name
        _id
        files
      }
    }
    allFishermanBusinessFile {
      nodes {
        _id
        file
        description
        gatsbyFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
    allFishermanBusinessTeam(
      sort: { order: ASC, fields: order }
      filter: { show: { eq: true } }
    ) {
      nodes {
        _id
        name
        role
        group
        email
        phone
        description
        image
        gatsbyImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        socialMedia {
          platform
          url
        }
        hours {
          startDay
          openTime
          closeTime
        }
        locations {
          _id
          name
        }
        show
      }
    }
  }
`;
